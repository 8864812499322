.black {
	color: @black;
}

.black-bg {
	background: @black;
}

.dark-grey {
	color: @dark-grey;
}

.dark-grey-bg {
	background: @dark-grey;
}

.grey {
	color: @grey;
}

.grey-bg {
	background: @grey;
}

.light-grey {
	color: @light-grey;
}

.light-grey-bg {
	background: @light-grey;
}

.off-white {
	color: @off-white;
}

.off-white-bg {
	background: @off-white;
}

.white {
	color: @white;
}

.white-bg {
	background: @white;
}

.blue1 {
	color: @blue1;
}

.blue1-bg {
	background: @blue1;
}

.blue2 {
	color: @blue2;
}

.blue2-bg {
	background: @blue2;
}

.red1 {
	color: @red1;
}

.red1-bg {
	background: @red1;
}

.red2 {
	color: @red2;
}

.red2-bg {
	background: @red2;
}

.yellow1 {
	color: @yellow1;
}

.yellow1-bg {
	background: @yellow1;
}

.yellow2 {
	color: @yellow2;
}

.yellow2-bg {
	background: @yellow2;
}

.green1 {
	color: @green1;
}

.green1-bg {
	background: @green1;
}

.green2 {
	color: @green2;
}

.green2-bg {
	background: @green2;
}

.orange1 {
	color: @orange1;
}

.orange1-bg {
	background: @orange1;
}

.orange2 {
	color: @orange2;
}

.orange2-bg {
	background: @orange2;
}

.aqua1 {
	color: @aqua1;
}

.aqua1-bg {
	background: @aqua1;
}

.aqua2 {
	color: @aqua2;
}

.aqua2-bg {
	background: @aqua2;
}

.purple1 {
	color: @purple1;
}

.purple1-bg {
	background: @purple1;
}

.purple2 {
	color: @purple2;
}

.purple2-bg {
	background: @purple2;
}

.navy1 {
	color: @navy1;
}

.navy1-bg {
	background: @navy1;
}

.navy2 {
	color: @navy2;
}

.navy2-bg {
	background: @navy2;
}
