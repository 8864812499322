.footer-languages {
  background: @inverse-background;
  color: @bw-black;
  padding: (@padding * 2) 0;
}

.footer-languages ul {
  margin-bottom: 0;
}

.footer-languages a {
  color: @white;
}

.footer-languages a:hover {
  color: @bw-black;
}


.footer {
  color: @bw-light-grey;
  margin: (@margin * 2) 0;
}

.footer a {
  color: @bw-light-grey;
}

.footer a:hover {
  color: @bw-grey;
}
