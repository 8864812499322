h1 {
  margin: 0;
  color: @bw-primary;
  font-weight: 300;
}

h5 {
  color: @bw-grey;
  text-transform: uppercase;
  font-weight: 700;
}

small {
  color: @bw-light-grey;
}
