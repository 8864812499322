

.modal-dialog {
  margin: 0;
  width: auto;
}

.modal-backdrop {
  background: @white;
  opacity: 0.9!important;
}

.modal-content {
  background: none;
  box-shadow: none;
  border: 0;
  text-align: center;

  -moz-border-radius: 0px;
  border-radius: 0px;
}

.modal-header {
  border-bottom: 0;
}

.close {
  color: @bw-black;
  text-shadow: none;
  opacity: 1;
}

.modal a {
  color: @bw-black;
  text-decoration: none;
  font-weight: 700;
  font-size: (@font-size * 4);
}

.modal a:hover {
  color: @bw-primary;
}
