


.btn {
  text-transform: uppercase;
}

.btn-primary {
  background: @bw-primary;
  border-color: @bw-primary;
  color: @white;
}

.btn-primary-test {
  background: @red1;
  border-color: @black;
  color: @white;
}

.btn-primary:hover {
  background: @bw-dark-primary;
  border-color: @bw-dark-primary;
}
