/* CONTENTS
/////////////////////////////////////
*/
/*
01. BASE
02. LAYOUT
03. MODULES
04. STATE

This stylesheet is a starting place. Include your own Base, Layout
Modules, and State styles below.

This style sheet organization is based on the SMACCS system.
Please visit the following website for more info: http://smacss.com/
*/
/****************************
/////////////////////////////

01. BASE

All base HTML tags that require styling

/////////////////////////////
****************************/
.container {
  padding: 0 100px;
}
.header {
  margin-top: 2em;
}
.header a {
  color: #212121;
  font-weight: bold;
}
.header a:hover {
  color: #727272;
}
.header-nav {
  margin-bottom: 10em;
}
.header ul {
  margin-bottom: 0;
}
.header ul.list-inline li {
  margin-right: 1.25em;
}
.btn {
  text-transform: uppercase;
}
.btn-primary {
  background: #009688;
  border-color: #009688;
  color: #ffffff;
}
.btn-primary-test {
  background: #e74c3c;
  border-color: #000;
  color: #ffffff;
}
.btn-primary:hover {
  background: #00796b;
  border-color: #00796b;
}
.well {
  border: 0;
  box-shadow: none;
  background-color: #ebebeb;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.modal-dialog {
  margin: 0;
  width: auto;
}
.modal-backdrop {
  background: #ffffff;
  opacity: 0.9!important;
}
.modal-content {
  background: none;
  box-shadow: none;
  border: 0;
  text-align: center;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.modal-header {
  border-bottom: 0;
}
.close {
  color: #212121;
  text-shadow: none;
  opacity: 1;
}
.modal a {
  color: #212121;
  text-decoration: none;
  font-weight: 700;
  font-size: 4em;
}
.modal a:hover {
  color: #009688;
}
.footer-languages {
  background: #009688;
  color: #212121;
  padding: 2em 0;
}
.footer-languages ul {
  margin-bottom: 0;
}
.footer-languages a {
  color: #ffffff;
}
.footer-languages a:hover {
  color: #212121;
}
.footer {
  color: #b6b6b6;
  margin: 2em 0;
}
.footer a {
  color: #b6b6b6;
}
.footer a:hover {
  color: #727272;
}
h1 {
  margin: 0;
  color: #009688;
  font-weight: 300;
}
h5 {
  color: #727272;
  text-transform: uppercase;
  font-weight: 700;
}
small {
  color: #b6b6b6;
}
.page-title {
  border-bottom: 5px solid #ebebeb;
  padding-bottom: 1em;
}
.add-content {
  line-height: 36px;
}
.feed {
  padding: 1em;
}
.post {
  margin-top: 2em;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 1.5em;
}
.post i {
  color: #ebebeb;
}
i.favorite {
  color: #e74c3c;
}
.sidebar {
  background: #ebebeb;
  padding: 1em;
  margin-bottom: 2em;
}
body {
  background: #ffffff;
  font-family: "Source Sans Pro", helvetica, arial, verdana, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #333;
}
/***************************
////////////////////////////

02. LAYOUT

Styles specific to your layout or website

////////////////////////////
***************************/
.footer {
  margin-top: 6em;
  padding-bottom: 12em;
  padding-top: 1em;
  border-top: 1px #ebebeb solid;
  color: #ebebeb;
}
/***************************
////////////////////////////

03. MODULES

Reusable classes or components

////////////////////////////
***************************/
.black {
  color: #000;
}
.black-bg {
  background: #000;
}
.dark-grey {
  color: #333;
}
.dark-grey-bg {
  background: #333;
}
.grey {
  color: #ccc;
}
.grey-bg {
  background: #ccc;
}
.light-grey {
  color: #ebebeb;
}
.light-grey-bg {
  background: #ebebeb;
}
.off-white {
  color: #f5f5f5;
}
.off-white-bg {
  background: #f5f5f5;
}
.white {
  color: #ffffff;
}
.white-bg {
  background: #ffffff;
}
.blue1 {
  color: #3498db;
}
.blue1-bg {
  background: #3498db;
}
.blue2 {
  color: #2980b9;
}
.blue2-bg {
  background: #2980b9;
}
.red1 {
  color: #e74c3c;
}
.red1-bg {
  background: #e74c3c;
}
.red2 {
  color: #c0392b;
}
.red2-bg {
  background: #c0392b;
}
.yellow1 {
  color: #f1c40f;
}
.yellow1-bg {
  background: #f1c40f;
}
.yellow2 {
  color: #f39c12;
}
.yellow2-bg {
  background: #f39c12;
}
.green1 {
  color: #2ecc71;
}
.green1-bg {
  background: #2ecc71;
}
.green2 {
  color: #27ae60;
}
.green2-bg {
  background: #27ae60;
}
.orange1 {
  color: #e67e22;
}
.orange1-bg {
  background: #e67e22;
}
.orange2 {
  color: #d35400;
}
.orange2-bg {
  background: #d35400;
}
.aqua1 {
  color: #1abc9c;
}
.aqua1-bg {
  background: #1abc9c;
}
.aqua2 {
  color: #16a085;
}
.aqua2-bg {
  background: #16a085;
}
.purple1 {
  color: #9b59b6;
}
.purple1-bg {
  background: #9b59b6;
}
.purple2 {
  color: #8e44ad;
}
.purple2-bg {
  background: #8e44ad;
}
.navy1 {
  color: #34495e;
}
.navy1-bg {
  background: #34495e;
}
.navy2 {
  color: #2c3e50;
}
.navy2-bg {
  background: #2c3e50;
}
/*

Potential modules you could replace...

badges

breadcrumb

buttons

button groups

button dropdowns

carousel

code

collapse

colors

dropdown

forms

icons

input groups

images

helpers

jumbotron

labels

list group

media object

modal

navs

navbar

pager

page header

pagination

panels

pills

popover

progress bars

scrollspy

tables

tabs

thumbnails

tooltip

typography

well


/***************************
////////////////////////////

04. STATES

////////////////////////////
***************************/
/*

Potential states you could replace...

alerts

form validation

*/
