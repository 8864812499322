.page-title {
  border-bottom: (@border-size * 5) @border-type @border-color;
  padding-bottom: @padding;
}


.add-content {
  line-height: 36px;
}


.feed {
 padding: @padding;
}

.post {
  margin-top: (@margin * 2);
  border-bottom: @border-size @border-type @border-color;
  padding-bottom: (@padding * 1.5);
}


.post i {
  color: @light-grey;
}

i.favorite {
  color: @red1;
}
